// Extended bootstrap utilities
.w-md-100 {
  @include media-breakpoint-up(md) {
    width: 100% !important;
  }
}
.w-md-25 {
  @include media-breakpoint-up(md) {
    width: 25% !important;
  }
}
.bg-md-none {
  @include media-breakpoint-up(md) {
    background: none !important;
  }
}
.page-fill {
  min-height: 62vh;
}
.object-fit-contain {
  object-fit: contain !important;
}

.rounded-top-left {
  border-top-left-radius: $border-radius;
}
.rounded-top-right {
  border-top-right-radius: $border-radius;
}
.rounded-bottom-left {
  border-bottom-left-radius: $border-radius;
}
.rounded-bottom-right {
  border-bottom-right-radius: $border-radius;
}

.bottom-0{
  bottom:0;
}
.top-0{
  top:0;
}
.left-0{
  left:0;
}
.right-0{
  right:0;
}
.end-0{
  right:0;
}

.flex-grow-2 {
  flex-grow: 2 !important
}

.bg-hover-primary:hover {
  background-color: $primary !important;
}

.bg-hover-secondary:hover {
  background-color: $secondary !important;
}

.bg-hover-info:hover {
  background-color: $info !important;
}

.bg-hover-danger:hover {
  background-color: $danger !important;
}

.bg-hover-success:hover {
  background-color: $success !important;
}

.bold {
  font-weight: bold !important;
}

.w-0 {
  // flex reset for equal size
  width: 0 !important;
}

.hyphens {
  hyphens: auto !important;
}

.links-white {
  a {
    color: $white !important
  }
}

.links-secondary {
  a {
    color: $secondary !important;
  }
}
