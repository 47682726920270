// Bootstrap variable overrides
//$enable-responsive-font-sizes: true !default;

$primary: #ffc000;

$theme-contrast-color: #fff !default;

$theme-colors: (
        'facebook': #1778F2,
        'twitter': #1DA1F2,
        'google-plus': #4285F4,
        'instagram': #D7005A,
        'linkedin': #0077b5,
        'pinterest': #e60023,
        'tumlbr': #35465c,
        'youtube': #FF0000,
) !default;

$default-theme-colors: (
        'contrast': $theme-contrast-color,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $default-theme-colors);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px,
        xxxl: 1900px
);

$custom-file-text: (
        en: "Browse",
        nl: "Bladeren"
) !default;

$rfs-base-font-size: .9rem !default;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
                (
                        0: 0,
                        1: ($spacer * .25),
                        2: ($spacer * .5),
                        3: $spacer,
                        4: ($spacer * 1.5),
                        5: ($spacer * 3)
                ),
                $spacers
);

$btn-border-radius: 50rem !default;
$btn-border-radius-lg: 50rem !default;
$btn-border-radius-sm: 50rem !default;

$btn-padding-x: map_get($spacers, 3) !default;
$btn-padding-x-sm: map_get($spacers, 4)/2 !default;
$btn-padding-x-lg: map_get($spacers, 4) !default;

@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700&family=Roboto:wght@300;400;700&display=swap');

$font-family-sans-serif: 'Roboto', sans-serif, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif: 'Cinzel', cursive, Georgia, "Times New Roman", Times, serif !default;
$font-family-base: $font-family-sans-serif !default;
$headings-font-family: $font-family-serif !default;

// Bootstrap
@import "~bootstrap/scss/bootstrap";

// Other imports
@import "include/cookieconsent";
@import "include/extended-utils";

// Hamburgers
//$hamburger-layer-width: 20px;
//$hamburger-layer-height: 3px;
//$hamburger-layer-spacing: 4px;
$hamburger-layer-color: $gray-600 !default;
$hamburger-active-layer-color: $gray-800 !default;
$hamburger-hover-opacity: 1 !default;
//$hamburger-hover-use-filter: true !default;

$hamburger-types: (
        squeeze
) !default;

@import "~hamburgers/_sass/hamburgers/hamburgers";

$owl-image-path: "~owl.carousel/src/img/";
@import "~owl.carousel/src/scss/owl.carousel";
@import "~owl.carousel/src/scss/owl.theme.default";

.hamburger {
  position: relative;

  .badge {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    transition: opacity 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  &.is-active {
    .badge {
      //display: none;
      opacity: 0;
    }
  }
}

.nav-cart {
  @extend .position-relative;

  .nav-link {
    @extend .px-3;

    &.disabled {
      .badge {
        display: none;
      }
    }
  }

  .badge {
    @extend .ml-2, .ml-lg-0;
    position: absolute;
    top: 0;
    right: 0;
  }

  small {
    .badge {
      top: -2px !important;
      right: -7px !important;
    }
  }
}

.dropdown-menu {
  @include media-breakpoint-down(md) {
    text-align: center;
  }
}

.importantInput {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

.container img {
  max-width: 100%;

  &:not([class]) {
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }
}

.modal-dialog-scrollable {
  max-height: subtract(100%, $modal-dialog-margin * 10);

  .modal-content {
    max-height: subtract(100vh, $modal-dialog-margin * 10); // IE10/11
  }
}

.has-user-content {
  img {
    height: auto !important;
  }

  // responsive prestyled tables on mobile
  table {
    height: auto !important;
    width: auto !important;
    table-layout: fixed;
    border: none;

    th, td {
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      border: 1px solid lighten($primary, 35%);
      padding: .5rem;
    }

    th {
      padding-top: .7rem;
      padding-bottom: .7rem;
      border-color: $primary !important;
      background-color: $primary !important;
      color: $white !important;
    }

    tr:nth-child(even) {
      background-color: $light;
    }

    tr:hover {
      background-color: darken($light, 3%);
    }

    @include media-breakpoint-down(md) {
      overflow-x: auto;
      display: block;
    }
  }

  @include media-breakpoint-down(md) {
    iframe {
      width: 100%;
    }
  }
}

.object-fit-contain {
  object-fit: contain !important;
}

.text-contrast {
  color: $theme-contrast-color;
}

.header {
  border-top: 5px solid $primary;

  .leaderboard {
    background-size: cover;
    background-position: center;
    padding: 150px 0;

    @include media-breakpoint-down(md) {
      padding: 50px 0;
    }

    h2, h5 {
      text-align: center;
      color: #FFF;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    }

    h2 {
      font-size: $spacer*3.5;
      @include media-breakpoint-down(md) {
        font-size: $spacer*1.5;
      }
    }

    h5 {
      font-size: 1.6rem;
      @include media-breakpoint-down(md) {
        font-size: $spacer;
      }
    }
  }

  .menu {
    background-color: $white;

    .navbar {
      padding: 0;

      .navbar-brand {
        img {
          padding: $spacer/2;
          width: 100px;
          @include media-breakpoint-down(md) {
            max-width: 80px;
          }
        }
      }

      .nav-item {
        .nav-link {
          padding: $spacer $spacer*.5;
          color: #333;
          //font-size: $spacer*1.25;
          margin-right: $spacer*.5;
          &:hover {
            background: rgba(0, 0, 0, 0.05);
          }

        }

        &:last-child {
          .nav-link {
            display: inline-block;
            background: #333;
            color: #FFF;
            border-radius: 50%;
            margin: $spacer/2;
            padding: $spacer/2 0.7rem;

            &:hover {
              background: #555;
            }
          }
        }
      }
    }
  }
}

.theme-list {
  margin-top: 30px;
  @include media-breakpoint-down(md) {
    margin-top:15px;
  }
  .card-inner {
    margin-bottom: 30px;
    @include media-breakpoint-down(md) {
      margin-bottom: 15px;
    }
  }

  .card {
    .card-title {
      padding: $spacer;
      color: $white;
      background: #404040;
      font-size: $spacer*1.5;
      font-weight: normal;
      margin-bottom: $spacer/4;
      @include media-breakpoint-down(lg) {
        font-size: $spacer*1.2;
      }
    }

    .card-image {
      position: relative;
    }
  }
}

.caption {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: $spacer*4 $spacer/2 $spacer $spacer/2;
  color: $white;
  font-size: 1$spacer*1.2;
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.5) 0%, rgba(255, 255, 255, 0) 90%);
}

.text-highlight {
  background-color: $primary;
  padding: $spacer*2;
  margin-bottom: $spacer*2;
  @include media-breakpoint-down(md) {
    padding: $spacer;
  }
}

.slider {
  .item {
    img {
      height: auto;
    }
  }

  .owl-nav {
    text-align: right;

    .owl-prev,
    .owl-next {
      margin-top: 0;
      margin-bottom: 0;

      span {
        display: block;
        line-height: 1;
        font-size: $spacer*2;
        background: #333;
        color: #FFF;
        border-radius: 50%;
        padding: 0 0.8rem 0.3rem 0.8rem;

        &:hover {
          background: #555;
        }
      }

      &:hover {
        background: none;
      }
    }
  }
}

.card {
  margin-bottom: $spacer;
  h1.card-title {
    font-size: $spacer*2;
  }

  .card-summary {
    margin-bottom: $spacer;
    font-size: $spacer*1.1;
    border-bottom: 1px solid #eeeeee;
  }
  .card-title {
    margin-top: $spacer*.5;
    margin-bottom: 0;
  }
}

.news-list {
  margin-bottom: $spacer*4;
}

.introduction {
  padding: $spacer;
  margin: $spacer 0;
  border-bottom: 3px solid $primary;

  h1 {
    font-size: $spacer*2;
    margin: 0;
  }
}

.footer-logo {
  margin-top: $spacer;
  padding-bottom: $spacer*2;

  .owl-carousel {
    .owl-stage {
      display: flex;
      align-items: center;

      .owl-item {
        img {
          height: auto;
          max-height: 70px;
          width: auto;
          padding: 0 10px;
          margin: 0 auto;
        }
      }
    }
  }
}

.copyright {
  padding-bottom: $spacer*4;

  a {
    color: #404040;
  }

  .fab {
    padding-top: 10px;
    font-size: $spacer*2;
  }
}
